export default {
  id: '02d5bda570',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">Left Page 2</div>\n    </div>\n  </div>\n  <div class="page-content">\n    <div class="block block-strong">\n      <p>Maxime tempore, repellendus? Sequi saepe provident quas, neque assumenda dicta odit eaque nesciunt facere mollitia totam voluptate aspernatur vel corporis repudiandae commodi ad cumque repellendus. Saepe officia iure repellat repellendus.</p>\n      <p>Ea maiores accusantium non fuga dicta, vero minus veniam! Ipsum eveniet vero voluptate veritatis aspernatur hic dicta adipisci, debitis. Sint quam aperiam repellat quis perspiciatis accusantium ipsum nulla soluta temporibus.</p>\n      <p>Necessitatibus ipsum culpa doloremque, nostrum atque totam minima itaque! Blanditiis nobis nam repudiandae, ut nostrum voluptate accusantium atque, veniam libero quaerat corporis laborum earum rem nihil unde. Vitae cum, aliquam?</p>\n      <p>Debitis aliquid nemo maxime recusandae, mollitia sed error vero. Atque molestiae rem necessitatibus nam voluptas quaerat, reiciendis, excepturi quis facilis, quod cupiditate vitae voluptate repudiandae! Unde impedit aut id ut?</p>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};